import React from "react"
import { Link } from "gatsby"
import Contact from "../components/contact"
import Header from "../components/header"
import SEO from "../components/seo"
import VideoBanner from "../components/videoBanner"
import TeamSegment from "../components/teamSegment"
import { team } from "../constants/team"
import { Segment, Image, Icon } from "semantic-ui-react"
import { document } from "browser-monads"

import teamVid from "../assets/video/team.mp4"
import teamImg from "../assets/img/team.jpg"

import "../css/team.css"

// Reset Scroll
document.body.scrollTo(0, 0)

const TeamPage = () => (
  <div>
    <VideoBanner text="Meet The Team" videoSrc={teamVid} imageSrc={teamImg} />
    <Header color="white" />
    <div className="about-bg-wrap">
      <div className="card-wrap team">
        {team && team.map((t, index) => <TeamSegment key={index} props={t} />)}
      </div>
    </div>
    <Contact />
  </div>
)

export default TeamPage
