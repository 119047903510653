import React from "react"
import { Segment, Icon } from "semantic-ui-react"
import { window } from "browser-monads"

const TeamSegment = props => {
  const { url, image, name, title, content, icons } = props.props

  return (
    <Segment
      onClick={() => {
        window.open(url, "_blank")
      }}
    >
      <div className="about-portrait" style={{backgroundImage: `url(${image})`}} />
      <div className="about-content-wrap">
        <h1>{name}</h1>
        <h4>{title}</h4>
        <p>{content}</p>
      </div>
      <div className="about-skills">
        {icons && icons.map((icon, index) => (
          <Icon key={index} name={icon} />
        ))}
      </div>
    </Segment>
  )
}

export default TeamSegment
