import michaelPortrait from "../assets/img/michael-portrait.jpeg"
import joshPortrait from "../assets/img/josh-portrait.jpeg"
// import chrisPortrait from "../assets/img/chris-portrait.jpeg"
import davidPortrait from "../assets/img/david-portrait.jpg"
import josephPortrait from "../assets/img/joseph-portrait.jpg"
//import ericPortrait from "../assets/img/eric-portrait.jpeg"

export const team = [
  {
    url: "https://www.linkedin.com/in/michael-paccione",
    image: michaelPortrait,
    name: "Michael Paccione",
    title: "Owner",
    content: `Hello, I am a San Francisco local who has spent the majority of my
      life operating businesses. Initially, I operated an animal service
      business for five years here while attending Academy of Art
      University to obtain a BFA in Web Design & New Media. I also spent a
      considerable amount of time focusing on developing my engineering
      talents. I have a couple apps in the Google Play Store and have
      enjoyed working with clients to develop their ideas as well!
      Currently, I operate this business full time in addition to
      performing my Chief Financial Officer duties at Enye, a 501c3
      international tech charity I co-founded. Enye takes software
      engineers in Nigeria to the next level while pairing them with
      entrepreneurs to help build the digital infrastructure of the
      future. We aim to improve the quality of life to those in the
      developing countries of Africa and the world at large.`,
    icons: [
      "chat",
      "handshake",
      "code",
      "css3 alternate",
      "js",
      "react",
      "digital ocean",
      "server",
    ],
  },
  {
    url: "https://www.linkedin.com/in/jkrubin/",
    image: joshPortrait,
    name: "Joshua Rubin",
    title: "Full Stack Engineer",
    content: `Hi. I am a graduate from San Francisco State University with a BS in
        Computer Science. I have experience with many programming languages,
        specializing in Node.js, React.js, PHP, and mySQL. I have experience
        with hosting and database management with AWS and creating RESTful
        APIs with Express.js. I have worked in Agile Software Development
        teams ranging from web development to game design. I am a creative
        problem solver, quick learner, and dedicated worker. I look forward
        to working with you!`,
    icons: [
      "chat",
      "code",
      "php",
      "js",
      "node js",
      "react",
      "database",
      "aws",
      "digital ocean",
      "server",
    ],
  },
  // {
  //   url: "https://www.linkedin.com/in/cpaccione/",
  //   image: chrisPortrait,
  //   name: "Christopher Paccione",
  //   title: "CMS Developer",
  //   content: `Hello, I'm a full-time Front-End Developer at an agency in downtown
  //       Chicago, and also freelance on UpWork. I'm experienced in Front-end Web Development and WordPress
  //       development and consulting. My formal education in Front-end Web
  //       Development is from General Assembly in NYC. Proficient in WordPress
  //       theme development, WooCommerce, WordPress theme installation and
  //       set-up, WordPress database migration, and WordPress plugin
  //       installation.`,
  //   icons: [
  //     "chat",
  //     "handshake",
  //     "code",
  //     "css3 alternate",
  //     "sass",
  //     "js",
  //     "php",
  //     "wordpress",
  //   ],
  // },
  {
    url: "https://www.linkedin.com/in/obodo-david-998786174/",
    image: davidPortrait,
    name: "David Obodo",
    title: "Front End Engineer",
    content: `I have worked on technical projects of some companies like OPPO mobile telecommunication Corp. Ltd (Nigeria), Sumo-Soft (London, United Kingdom) which is a prestigious company that powers "Archibald London" E-commerce site and "Richard James" E-commerce site, and Enye (International Tech Charity from San Francisco).
    
    I now work in a web agency “M.Paccione Design & Development” for Enye's Chief Financial Officer. We met though Enye, an engineering/startup accelerator, beating almost a thousand developers, to be accepted into the program.
    
    I build fully responsive pixel-perfect websites from UI mock-ups and leverage on tech stacks such as React.JS, Redux, Styled-components and what have you, depending on the requirements of the job. Extremely conversant with Vanilla Javascript, hence can easily adapt to any framework.`,
    icons: [
      "chat",
      "code",
      "css3 alternate",
      "sass",
      "js",
      "react",
      "wordpress",
    ],
  },
  {
    url: "https://www.linkedin.com/in/godinson/",
    image: josephPortrait,
    name: "Joseph Godwin",
    title: "Full Stack Engineer Intern",
    content: `I am a full-stack web software developer with experience building web applications using the latest web technologies. My past work experience was at Enye as a full stack developer. I was involved in building user interfaces and it's corresponding backend. I build mobile responsive websites using HTML, CSS, Javascript, and React. I also do backend development with Node JS and Firebase.`,
    icons: [
      "chat",
      "code",
      "css3 alternate",
      "js",
      "react",
      // "php",
      "database",
      // "aws",
      // "digital ocean",
      "server",
    ],
  },
  // {
  //   url: "https://www.linkedin.com/in/engreric000/",
  //   image: ericPortrait,
  //   name: "Eric Okemmadu",
  //   title: "Full Stack Engineer Intern",
  //   content: `I am a highly organized and detail-oriented professional, dedicated
  //     full-stack web developer and an information technology manager who
  //     is proficient in MERN stack, javascript/typescript, WordPress,
  //     flutter, aws, firebase, Express gateway, docker, and many other
  //     frameworks. I have 3+ years of progressive industrial experience
  //     working on both companies and freelance jobs. I am adept with the
  //     use of agile methodologies to reach milestones by identifying
  //     critical paths in a project and tackling them beforehand. A Talented
  //     Software Engineer who is proficient in all phases of the software
  //     development life-cycle. I graduated in the department of information Management
  //     Technology at Federal University of technology owerri Nigeria with
  //     CGPA of 4.3/5.0. I was one of the best graduating student.`,
  //   icons: [
  //     "code",
  //     "css3 alternate",
  //     "js",
  //     "node js",
  //     "react",
  //     "database",
  //     "aws",
  //     "docker",
  //   ],
  // },
]
